export const userRole = {
  superUser: 'super_admin',
  admin: 'admin',
  provincial: 'pr_supervisor',
  institute: 'ins_manager',
  user: 'user',
  dormManager: 'dorm_manager',
  instituteDataentry: 'ins_dataentry',
  instituteManager: 'ins_manager',
  authorityDataentry: 'hq_dataentry',
  authoritySupervisor: 'hq_supervisor',
  provinceDataentry: 'pr_dataentry',
  provinceSupervisor: 'pr_supervisor',
  dataentry: 'dataentry',
  supervisor: 'supervisor',
  authenticated: 'authenticated',
  tester: 'tester',
  teacherTraining: 'teacher_training',
  evaluation: 'evaluation',
  humanResource: 'human_resource',
  nasab: 'nasab',
  plan: 'plan',
};

function persianToEnglishDigits(persianNumber) {
  const persianDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  const englishDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  for (let i = 0; i < persianDigits.length; i++) {
    persianNumber = persianNumber.replace(
      new RegExp(persianDigits[i], 'g'),
      englishDigits[i],
    );
  }

  return persianNumber;
}

// calculate current shamsi year using mutliDatepicker library
export const CURRENT_SHAMSI_YEAR = Number(
  persianToEnglishDigits(new Date().toLocaleDateString('fa-IR').split('/')[0]),
);

console.log('CURRENT_SHAMSI_YEAR', CURRENT_SHAMSI_YEAR);

export const roleRoots = [
  { group: 'admin', priority: 0, route: '/app' },
  { group: 'authority', priority: 0, route: '/app' },
  { group: 'province', priority: 0, route: '/app/dashboards/provincial' },
  { group: 'teacher', priority: 0, route: '/app/courses' },
  { group: 'institute', priority: 0, route: '/app/dashboards/institute' },

  // gradually remove the following roles or create seperate dashboards for each role
  { group: 'plan', priority: 0, route: '/app' },
  { group: 'nasab', priority: 0, route: '/app' },
  { group: 'hq_supervisor', priority: 1, route: '/app' },
  { group: 'supervisor', priority: 1, route: '/app' },
  { group: 'dorm_manager', priority: 1, route: '/app/dorms/students' },
  { group: 'ins_manager', priority: 2, route: '/app/dashboards/institute' },
  { group: 'ins_dataentry', priority: 3, route: '/app/students' },
  { group: 'pr_supervisor', priority: 1, route: '/app/dashboards/provincial' },
  { group: 'pr_dataentry', priority: 2, route: '/app/dashboards/provincial' },
  { group: 'human_resource', priority: 0, route: '/app/employees/employees' },
  { group: 'dataentry', priority: 5, route: '/app/employees/employees' },
];

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'fa';
export const localeOptions = [
  { id: 'fa', name: 'دری', direction: 'rtl' },
  // { id: 'ps', name: 'پښتو', direction: 'rtl' },
  { id: 'en', name: 'English', direction: 'ltr' },
  // ,
  // { id: 'es', name: 'Español', direction: 'ltr' },
  // { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

// export const firebaseConfig = {
//   apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
//   authDomain: 'gogo-react-login.firebaseapp.com',
//   databaseURL: 'https://gogo-react-login.firebaseio.com',
//   projectId: 'gogo-react-login',
//   storageBucket: 'gogo-react-login.appspot.com',
//   messagingSenderId: '216495999563',
// };

export const currentUser = {
  id: 1,
  title: 'سمیع الله رحیمی',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'آخرین بازدید امروز 15:24',
  role: [userRole.admin, userRole.Editor],
};

export const adminRoot = '/app';
export const buyUrl = 'https://www.rtl-theme.com/?p=94491';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'rtl';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
export const tasdeeqTypes = [
  { value: 'sickness', label: 'مریضی' },
  { value: 'necessary_work', label: 'ضروری کار' },
  { value: 'other', label: 'دگر' },
];