import { message } from 'antd';

import axios from 'axios';

message.config({
  top: 100,
  duration: 2,
  maxCount: 3,
  rtl: true,
  prefixCls: 'my-message',
});

// const servicePath = 'http://172.16.105.244/tveta'; #production mood
// export const servicePath = 'http://0.0.0.0:8000';
// export const servicePath = 'http://172.16.104.105:8000';
export const servicePath = 'https://online.tveta.gov.af:8000';

//  sets authentication header and content-type
const getHeaders = (data) => {
  const user = localStorage.getItem('user');
  const access_token = localStorage.getItem('access_token');
  if (user && access_token) {
    const headers = {
      Authorization: `Bearer ${access_token}`,
      ...data?.headers,
    };

    // if data includes files, send request as multipart/form-data
    if (data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    }
    return headers;
  } else {
    return {};
  }
};

// make API calls
// use an object instead of seperate arguments
const callApi = async (
  endpoint,
  method = 'get',
  data = null,
  params = null,
  headers = {},
) => {
  const myHeaders = { ...getHeaders(data), ...headers };
  const url = `${servicePath}/${endpoint}`;
  try {
    const response = await axios({
      method,
      url,
      headers: myHeaders,
      data,
      params,
    });

    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 403) {
        return message.warning('شما صلاحیت این عمل را ندارید');
      }
      if (error.response.status === 404) {
        return message.warning('در سیستم یافت نشد');
      }
    } else if (error.request) {
      // The request was made but no response was received
      return message.warning('ریکویسټ سرور ته ولیږل شوه،‌خو ځواب رانغی');
    } else {
      // Something happened in setting up the request that triggered an Error
      message.warning('ریکویسټ جوړولو کې مشکل رامینځته شو');
    }
    console.log('error in callAPI: ', error);
    console.log(endpoint);
    throw error;
    // return false;
  }
};

export default callApi;
