import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from 'constants/defaultValues';
import callApi from './callApi';
import { DateObject } from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';

// Function to replace non-English digits with English ones
export const replaceNonEnglishDigits = (input) => {
  const nonEnglishDigits = /[\u0660-\u0669\u06F0-\u06F9]/g; // Match Arabic and Persian digits
  return input.replace(nonEnglishDigits, (match) => {
    // Define a mapping of non-English digits to English digits
    const digitMap = {
      '٠': '0',
      '١': '1',
      '٢': '2',
      '٣': '3',
      '٤': '4',
      '٥': '5',
      '٦': '6',
      '٧': '7',
      '٨': '8',
      '٩': '9',
      '۰': '0',
      '۱': '1',
      '۲': '2',
      '۳': '3',
      '۴': '4',
      '۵': '5',
      '۶': '6',
      '۷': '7',
      '۸': '8',
      '۹': '9',
    };
    return digitMap[match];
  });
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // Add smooth scrolling behavior
  });
};

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const isDarkMode = () => {
  let currentColor = defaultColor;
  if (localStorage.getItem(themeColorStorageKey)) {
    currentColor = localStorage.getItem(themeColorStorageKey);
  }

  return currentColor.indexOf('dark') > -1;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error,
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error,
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage'),
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error,
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error,
    );
  }
};
export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('user') != null ? localStorage.getItem('user') : '';
  } catch (error) {
    console.log('Error in getCurrentUser:', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      console.log('setuser', user);
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  } catch (error) {
    console.log('Error in setCurrentUser:', error);
  }
};

// export const getCurrentUser = () => {
//   let user = null;
//   try {
//     user =
//       localStorage.getItem('gogo_current_user') != null
//         ? JSON.parse(localStorage.getItem('gogo_current_user'))
//         : null;
//   } catch (error) {
//     console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
//     user = null;
//   }
//   return user;
// };

// export const setCurrentUser = (user) => {
//   try {
//     if (user) {
//       localStorage.setItem('gogo_current_user', JSON.stringify(user));
//     } else {
//       localStorage.removeItem('gogo_current_user');
//     }
//   } catch (error) {
//     console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
//   }
// };

export const limitOptions = (options, optionKey, filterValue) => {
  return options.filter((option) => option[String(optionKey)] === filterValue);
};

export const handleLogout = async () => {
  try {
    console.log('logging out');

    // Make the logout API call
    const response = await callApi('auth/logout/', 'POST', {
      refresh: localStorage.getItem('refresh_token'),
    });
    console.log('response from logout: ', response);
  } catch (error) {
    console.log('error in logout: ', error);
  } finally {
    // Clear local storage items and redirect to the homepage
    clearLocalStorage();
    redirectToHomepage();
  }
};

// Helper function to clear local storage items
const clearLocalStorage = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user');
  localStorage.removeItem('current_user');
};

// Helper function to redirect to the homepage
const redirectToHomepage = () => {
  window.location.href = window.location.origin;
};

export function gregorianToShamsi(gregorianDate) {
  return new DateObject(gregorianDate).convert(persian, persian_fa).toString();
}
